import {useFormik} from 'formik'
import {compact} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {KTSVG} from '../../../../../_gori/helpers'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import {Button, SelectFormik} from '../../../../../_gori/partials/widgets'
import A1BatchService from '../../core/_requests'

type Props = {
  show: boolean
  data: {orderIds: string[]}
  handleSubmit: (values) => void
  handleClose: () => void
}

const A1ShipFromOrderModal: React.FC<Props> = ({show = false, data, handleSubmit, handleClose}) => {
  const intl = useIntl()
  const {isCancel, newCancelToken} = useCancelToken()
  const [loading, setLoading] = useState<{first: boolean}>({first: false})
  const [optionsFromAddress, setOptionsFromAddress] = useState<any[]>([])

  const getFromAddress = useCallback(async () => {
    try {
      setLoading((prev) => ({...prev, first: true}))
      const {from} = await A1BatchService.checkFromAddress(
        {
          order_ids: data.orderIds,
        },
        {cancelToken: newCancelToken()}
      )
      if (from) {
        setOptionsFromAddress(
          from.map((item: any) => {
            const {first_name, last_name, company, street1, city, state, zip, is_verified} = item
            const fullName = compact([first_name, last_name]).join(', ')
            const labelOption = (
              <div>
                <div className='fw-bolder'>{company}</div>
                <div className='fs-7'>{fullName}</div>
                <div className='d-block'>{compact([street1, city, state, zip]).join(', ')}</div>
                {is_verified && (
                  <div className='text-success'>{intl.formatMessage({id: 'VERIFIED_ADDRESS'})}</div>
                )}
              </div>
            )
            return {
              label: labelOption,
              value: item,
            }
          })
        )
      }
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setLoading((prev) => ({...prev, first: false}))
    }
  }, [data.orderIds, intl, isCancel, newCancelToken])

  useEffect(() => {
    getFromAddress()
  }, [getFromAddress])

  const initValidateSchema = useMemo(() => {
    return {
      shipFrom: Yup.object()
        .nullable()
        .required(
          intl.formatMessage(
            {id: 'INPUT_IS_REQUIRED'},
            {input: intl.formatMessage({id: 'SHIP_FROM'})}
          )
        ),
    }
  }, [intl])

  const formik = useFormik({
    initialValues: {
      shipFrom: null,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape(initValidateSchema),
    onSubmit: (values) => {
      handleSubmit(values.shipFrom)
      handleClose()
    },
  })

  return (
    <Modal
      id='gori_modal_a1_ship_from_order'
      tabIndex={-1}
      aria-hidden='true'
      centered
      dialogClassName='mw-500px h-auto'
      show={show}
      backdrop='static'
      onHide={() => {
        handleClose()
      }}
    >
      <div className='modal-content'>
        <Modal.Header closeButton>
          <Modal.Title bsPrefix={'fw-bolder fs-1'}>
            {intl.formatMessage({id: 'CHOOSE_FROM_ADDRESS'})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <KTSVG path='/media/gori/errors/error.svg' className='text-danger' small={false} />
            <div className='mt-6 fs-4 fw-bold'>
              <div className='d-flex flex-column align-items-center justify-content-center'>
                <div>{intl.formatMessage({id: 'MULTIPLE_FROM_ADDRESSES_HAVE_BEEN_DETECTED'})}</div>
                <div className='mt-2'>
                  {intl.formatMessage({id: 'PLEASE_SELECT_ONE_FROM_ADDRESS_FOR_THE_BATCH'})}
                </div>
              </div>
              <div className='mt-3'>
                <SelectFormik
                  options={optionsFromAddress}
                  label={intl.formatMessage({id: 'SHIP_FROM'})}
                  labelClassName='col-form-label fw-bolder'
                  name={'shipFrom'}
                  formik={formik}
                  required
                  placeholder={intl.formatMessage({id: 'PLEASE_CHOOSE'})}
                  isSearchable={false}
                  emptyDefault={false}
                  disabled={loading.first}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='row'>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-3'
                label={intl.formatMessage({id: 'CANCEL'})}
                event={handleClose}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'SUBMIT'})}
                event={formik.handleSubmit}
              />
            </div>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  )
}

export {A1ShipFromOrderModal}
