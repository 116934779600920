import clsx from 'clsx'
import {useFormik} from 'formik'
import {find, isEmpty} from 'lodash'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {ConfirmActionModal} from '../../../../../_gori/components'
import {KTSVG} from '../../../../../_gori/helpers'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import UseCancelTokenHasKey from '../../../../../_gori/hooks/UseCancelTokenHasKey'
import UseYupValidate from '../../../../../_gori/hooks/UseYupValidate'
import {
  Button,
  InputTextFormik,
  SelectFormik,
  ValidationErrorModal,
} from '../../../../../_gori/partials/widgets'
import HttpStatusCode from '../../../../api/HttpStatusCode'
import {A1ShipFromOrderModal} from '../../../a1'
import {useAuth} from '../../../auth'
import {FileErrorModal} from '../../../batches'
import A1_CONSTANTS from '../../core/_const'
import A1BatchService from '../../core/_requests'
import {A1BatchDuplicateModal} from './A1BatchDuplicateModal'

type Props = {
  show: boolean
  handleClose: () => void
  orders?: any
  file?: any
  reloadTable: () => any
}

const AddToA1BatchModal: React.FC<Props> = ({
  show,
  handleClose,
  orders,
  file = null,
  reloadTable,
}) => {
  const intl = useIntl()
  const {loadingSwitch} = useAuth()
  const {stringYup} = UseYupValidate()
  const {newCancelToken, isCancel} = useCancelToken()
  const {newCancelTokenHasKey, isCancelHasKey} = UseCancelTokenHasKey()
  const [loading, setLoading] = useState<{first: boolean; submit: boolean}>({
    first: true,
    submit: false,
  })
  const [hasShow, setHasShow] = useState<{
    smart: boolean
    create: boolean
    existing: boolean
    batchesDuplicate: boolean
    fromAddressOrders: boolean
    confirmAction: boolean
    validateFile: boolean
  }>({
    smart: false,
    create: false,
    existing: false,
    batchesDuplicate: false,
    fromAddressOrders: false,
    confirmAction: false,
    validateFile: false,
  })
  const [dataModal, setDataModal] = useState<{
    batchesDuplicate: any
    fromAddressOrders: any
    confirmAction: string
    validateFile: any
  }>({
    batchesDuplicate: [],
    fromAddressOrders: [],
    confirmAction: '',
    validateFile: [],
  })
  const [hasPass, setHasPass] = useState<{shipFrom: boolean}>({shipFrom: false})
  const [validationErrors, setValidationErrors] = useState<any>()
  const [progress, setProgress] = useState(0)
  const [optionsBatches, setOptionsBatches] = useState<any>([])

  // BEGIN:  fake progress
  // setProgress(0)
  // const fakeProgressInterval = setInterval(() => {
  //   setProgress((prevProgress) => {
  //     if (prevProgress < 80) {
  //       return prevProgress + 10
  //     }
  //     return prevProgress
  //   })
  // }, 500)

  // clearInterval(fakeProgressInterval)
  // setProgress(100)
  // END:  fake progress

  const initValidateSchema = useMemo(() => {
    return {
      active_box: Yup.string()
        .nullable()
        .oneOf(
          [...Object.values(A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX), 'null'],
          intl.formatMessage({id: 'INVALID_OPTION'})
        )
        .required(intl.formatMessage({id: 'PLEASE_SELECT_AT_LEAST_ONE_OPTION'})),
      smart: Yup.string()
        .nullable()
        .when('active_box', {
          is: A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.SMART,
          then: stringYup(255, 'SMART_A1_BATCH'),
        }),
      create: Yup.string()
        .nullable()
        .when('active_box', {
          is: A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.CREATE,
          then: stringYup(255, 'A1_BATCH_NAME'),
        }),
      existing: Yup.string()
        .nullable()
        .when('active_box', {
          is: A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.EXISTING,
          then: stringYup(255, 'SELECT_A_A1_BATCH'),
        }),
    }
  }, [intl, stringYup])

  const handleFileUpload = async (values) => {
    if (!file) return

    const showConfirmModal = (messageKey: string) => {
      setDataModal((prev) => ({
        ...prev,
        confirmAction: messageKey,
      }))
      setHasShow((prev) => ({...prev, confirmAction: true}))
    }

    if (!hasPass.shipFrom) {
      return showConfirmModal(
        'THE_1ST_RECORD_WILL_OVERWRITE_THE_FROM_ADDRESS_FOR_ALL_ORDERS_IN_THE_BATCH'
      )
    }

    const createPayload = () => {
      const payload = new FormData()
      payload.append('file', file)
      payload.append('type', 'create')

      if (values.active_box === A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.CREATE) {
        payload.append('name', values.create || '')
      } else if (values.active_box === A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.EXISTING) {
        payload.append('existing_batch', values.existing || '')
      }

      return payload
    }

    const payload = createPayload()
    const config = {
      headers: {'content-type': 'multipart/form-data'},
      cancelToken: newCancelToken(),
    }

    try {
      setLoading((prev) => ({...prev, submit: true}))
      const res = await A1BatchService.store(payload, config)

      if (res) {
        toast.success(intl.formatMessage({id: 'ADDED_TO_A1_BATCH_SUCCESSFULLY'}))
        reloadTable()
        handleClose()
      }
    } catch (error: any) {
      if (isCancel(error)) return
      if (error?.response?.status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
        setDataModal((prev) => ({...prev, validateFile: error?.response?.data?.error?.fields}))
        setHasShow((prev) => ({...prev, validateFile: true}))
      } else {
        setValidationErrors(error?.response)
      }
    } finally {
      setLoading((prev) => ({...prev, submit: false}))
    }
  }

  const checkOrderInBatch = useCallback(
    async (batchId) => {
      setLoading((prev) => ({...prev, submit: true}))
      try {
        const {from} = await A1BatchService.getDetail(batchId, {
          cancelToken: newCancelTokenHasKey('check_order_in_batch'),
        })
        return !isEmpty(from)
      } catch (error) {
        if (isCancelHasKey(error)) return
        return false
      } finally {
        setLoading((prev) => ({...prev, submit: false}))
      }
    },
    [isCancelHasKey, newCancelTokenHasKey]
  )

  const handleOrderProcessing = async (values) => {
    const orderChecked = orders.reduce(
      (acc: any, order: any) => {
        const key = order.original.a1_batch_id ? 'hasBatch' : 'noBatch'
        acc[key].push(order.original.id)
        return acc
      },
      {hasBatch: [], noBatch: []}
    )

    const showModal = (type: string, data: any) => {
      setDataModal((prev) => ({...prev, [type]: data}))
      setHasShow((prev) => ({...prev, [type]: true}))
    }

    if (!hasPass.shipFrom) {
      const isOrderInBatch =
        values.active_box === A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.EXISTING &&
        (await checkOrderInBatch(values.existing))

      if (
        values.active_box === A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.CREATE ||
        !isOrderInBatch
      ) {
        showModal('fromAddressOrders', {
          orderIds: [...orderChecked.hasBatch, ...orderChecked.noBatch],
        })
      } else if (isOrderInBatch) {
        showModal(
          'confirmAction',
          'THE_FROM_ADDRESS_OF_SELECTED_A1_BATCH_WILL_OVERWRITE_THE_FROM_ADDRESS_FOR_ALL_ORDERS_IN_THE_BATCH'
        )
      }
      return
    }

    const payload = {
      type: A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.CREATE,
      has_batch: orderChecked.hasBatch,
      no_batch: orderChecked.noBatch,
      from: values.shipFrom,
      ...(values.active_box === A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.CREATE
        ? {name: values.create}
        : {existing_batch: values.existing}),
    }

    if (orderChecked.hasBatch.length > 0) {
      showModal('batchesDuplicate', {
        payload,
        name_position:
          values.active_box === A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.EXISTING
            ? find(optionsBatches, {value: values.existing}).label
            : values.create,
      })
    } else {
      const config = {cancelToken: newCancelToken()}

      try {
        setLoading((prev) => ({...prev, submit: true}))
        const res = await A1BatchService.store(payload, config)

        if (res) {
          toast.success(intl.formatMessage({id: 'ADDED_TO_A1_BATCH_SUCCESSFULLY'}))
          reloadTable()
          handleClose()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, submit: false}))
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      active_box: null,
      smart: null,
      create: null,
      existing: null,
      shipFrom: null,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape(initValidateSchema),
    onSubmit: async (values) => {
      if (file) {
        await handleFileUpload(values)
      } else {
        await handleOrderProcessing(values)
      }
    },
  })

  const getBatches = useCallback(async () => {
    const config = {
      params: {
        all: true,
        latest: true,
        status: A1_CONSTANTS.A1_BATCH_STATUS.OPEN,
      },
      cancelToken: newCancelToken(),
    }
    try {
      const [{a1_batches}, {a1_batch}] = await Promise.all([
        A1BatchService.getList(config),
        A1BatchService.getA1BatchLatest({cancelToken: newCancelToken()}),
      ])

      let nameBatch = (a1_batch?.id ? a1_batch?.id + 100001 : 100001).toString()
      if (file) {
        nameBatch = `${nameBatch} ${file.name}`
      }

      formik.setFieldValue('create', nameBatch)
      setOptionsBatches(
        a1_batches.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      )
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setLoading((prev) => ({...prev, first: false}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancel, newCancelToken])

  useEffect(() => {
    if (loadingSwitch) return
    getBatches()

    return () => {}
  }, [getBatches, loadingSwitch])

  // BEGIN: Action Duplicate Modal
  const handleDuplicateClose = () => {
    setHasShow((prev) => ({...prev, batchesDuplicate: false}))
    handleClose()
  }

  const handleDuplicateSuccess = () => {
    reloadTable()
    handleClose()
  }
  // END: Action Duplicate Modal

  // BEGIN: Action Ship From Modal
  const handleShipFromSubmit = (value) => {
    formik.setFieldValue('shipFrom', value)
    setHasPass((prev) => ({...prev, shipFrom: true}))
    formik.handleSubmit()
  }

  const handleShipFromClose = () => {
    setDataModal((prev) => ({...prev, fromAddressOrders: []}))
    setHasShow((prev) => ({...prev, fromAddressOrders: false}))
  }
  // END: Action Ship From Modal

  // BEGIN: Action Confirm Modal
  const contentConfirmAction = useMemo(() => {
    return (
      <div className='fw-bold'>
        <div className='d-flex flex-column align-items-center justify-content-center'>
          <div>{intl.formatMessage({id: 'MULTIPLE_FROM_ADDRESSES_HAVE_BEEN_DETECTED'})}</div>
          <div className='mt-2 text-center'>
            {dataModal?.confirmAction && intl.formatMessage({id: dataModal?.confirmAction})}
          </div>
        </div>
      </div>
    )
  }, [dataModal.confirmAction, intl])

  const handleConfirmPrimaryAction = () => {
    setHasPass((prev) => ({...prev, shipFrom: true}))
    setHasShow((prev) => ({...prev, confirmAction: false}))
    formik.handleSubmit()
  }

  const handleConfirmSecondaryAction = () => {
    setHasPass((prev) => ({...prev, shipFrom: false}))
    setHasShow((prev) => ({...prev, confirmAction: false}))
  }

  const handleConfirmClose = () => {
    setHasPass((prev) => ({...prev, shipFrom: false}))
    setHasShow((prev) => ({...prev, confirmAction: false}))
  }
  // END: Action Confirm Modal

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      {hasShow.validateFile && (
        <FileErrorModal
          show={hasShow.validateFile}
          handleClose={() => setHasShow((prev) => ({...prev, validateFile: false}))}
          errors={dataModal.validateFile}
        />
      )}
      {hasShow.batchesDuplicate && (
        <A1BatchDuplicateModal
          show={hasShow.batchesDuplicate}
          data={dataModal.batchesDuplicate}
          handleClose={handleDuplicateClose}
          handleSuccess={handleDuplicateSuccess}
        />
      )}
      {hasShow.fromAddressOrders && (
        <A1ShipFromOrderModal
          show={hasShow.fromAddressOrders}
          data={dataModal.fromAddressOrders}
          handleSubmit={handleShipFromSubmit}
          handleClose={handleShipFromClose}
        />
      )}
      {hasShow.confirmAction && (
        <ConfirmActionModal
          show={hasShow.confirmAction}
          title={intl.formatMessage({id: 'FROM_ADDRESS_OVERWRITE'})}
          message={contentConfirmAction}
          messagePrimary={intl.formatMessage({id: 'SUBMIT'})}
          messageSecondary={intl.formatMessage({id: 'CANCEL'})}
          handlePrimaryAction={handleConfirmPrimaryAction}
          handleSecondaryAction={handleConfirmSecondaryAction}
          handleClose={handleConfirmClose}
        />
      )}
      <Modal
        id='gori_modal_add_to_A1_batch_modal'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-800px h-auto'
        show={show && !hasShow.batchesDuplicate}
        backdrop='static'
        onHide={() => handleClose()}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'ADD_TO_A1_BATCH'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              {loading.first ? (
                Array.from({length: 3}).map((item, index) => (
                  <div
                    className='d-flex flex-column border rounded mb-6 p-6 border-secondary cursor-no-drop'
                    key={index}
                  >
                    <div className='fs-2 col-4 mb-3 placeholder placeholder-lg rounded-2 bg-secondary' />
                    <div className='fs-5 col-8 placeholder placeholder-lg rounded-2 bg-secondary' />
                  </div>
                ))
              ) : (
                <div>
                  <div
                    onClick={() => {
                      formik.setFieldValue(
                        'active_box',
                        A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.SMART
                      )
                    }}
                    className={clsx('border rounded mb-6 p-6', {
                      'border-secondary':
                        formik.values.active_box !==
                        A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.SMART,
                      'opacity-50':
                        formik.values.active_box &&
                        formik.values.active_box !==
                          A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.SMART,
                      'border-primary':
                        formik.values.active_box ===
                        A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.SMART,
                      'opacity-50 pe-none': true,
                    })}
                  >
                    <div>
                      <h3 className='fw-bolder fs-2 d-flex align-items-center mb-3'>
                        {intl.formatMessage({id: 'SMART_A1_BATCH'})}
                        <KTSVG
                          path='/media/gori/a1/verify.svg'
                          className={clsx('svg-icon svg-icon-1 ms-2', {
                            'text-primary':
                              formik.values.active_box ===
                              A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.SMART,
                          })}
                        />
                      </h3>
                      <span
                        className={clsx('', {
                          'text-gori fw-bolder':
                            formik.values.active_box ===
                            A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.SMART,
                        })}
                      >
                        {intl.formatMessage({id: 'GIVE_A_SUGGESTION_USING_GORI_AI_OPTIMIZATION'})}
                      </span>
                    </div>
                    {formik.values.active_box ===
                      A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.SMART && (
                      <div className='progress h-6px mt-6'>
                        <div
                          role='progressbar'
                          className='progress-bar bg-image-gori'
                          style={{width: `${progress}%`}}
                          aria-valuenow={progress}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        ></div>
                      </div>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      formik.setFieldValue(
                        'active_box',
                        A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.CREATE
                      )
                    }}
                    className={clsx('border rounded mb-6 p-6 cursor-pointer', {
                      'border-secondary':
                        formik.values.active_box !==
                        A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.CREATE,
                      'opacity-50':
                        formik.values.active_box &&
                        formik.values.active_box !==
                          A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.CREATE,
                      'border-primary':
                        formik.values.active_box ===
                        A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.CREATE,
                    })}
                  >
                    <div>
                      <h3 className='fw-bolder fs-2 mb-3'>
                        {intl.formatMessage({id: 'CREATE_NEW_A1_BATCH'})}
                      </h3>
                      <span className=''>
                        {intl.formatMessage({id: 'GIVE_A_UNIQUE_NAME_FOR_YOUR_A1_BATCH'})}
                      </span>
                    </div>
                    {formik.values.active_box ===
                      A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.CREATE && (
                      <div className='mt-6'>
                        <InputTextFormik
                          label={intl.formatMessage({id: 'A1_BATCH_NAME'})}
                          formik={formik}
                          name={A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.CREATE}
                          placeholder='100000'
                          disabled={loading.submit}
                          required
                        />
                      </div>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      formik.setFieldValue(
                        'active_box',
                        A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.EXISTING
                      )
                    }}
                    className={clsx('border rounded mb-6 p-6 cursor-pointer', {
                      'border-secondary':
                        formik.values.active_box !==
                        A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.EXISTING,
                      'opacity-50':
                        formik.values.active_box &&
                        formik.values.active_box !==
                          A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.EXISTING,
                      'border-primary':
                        formik.values.active_box ===
                        A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.EXISTING,
                    })}
                  >
                    <div>
                      <h3 className='fw-bolder fs-2 mb-3'>
                        {intl.formatMessage({id: 'ADD_TO_EXISTING_A1_BATCH'})}
                      </h3>
                      <span className=''>
                        {intl.formatMessage({id: 'SELECT_A_BATCH_TO_ADD_YOUR_ORDER_TO'})}
                      </span>
                    </div>
                    {formik.values.active_box ===
                      A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.EXISTING && (
                      <div className='mt-6'>
                        <SelectFormik
                          label={intl.formatMessage({id: 'SELECT_A_A1_BATCH'})}
                          placeholder={intl.formatMessage({id: 'BATCH'})}
                          formik={formik}
                          name={A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTIVE_BOX.EXISTING}
                          options={optionsBatches}
                          disabled={loading.submit}
                          emptyDefault={false}
                          required
                        />
                      </div>
                    )}
                  </div>
                  {formik.errors.active_box && formik.touched.active_box && (
                    <div className='mt-4 text-danger text-center'>{formik.errors.active_box}</div>
                  )}
                </div>
              )}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-3'
                label={intl.formatMessage({id: 'CANCEL'})}
                event={() => handleClose()}
                disabled={loading.submit}
              />
              <Button
                className={'btn btn-primary'}
                label={intl.formatMessage({id: 'SUBMIT'})}
                loadingText={intl.formatMessage({id: 'SUBMIT'})}
                disabled={loading.first || loading.submit}
                loading={loading.submit}
                event={formik.handleSubmit}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {AddToA1BatchModal}
