import {isEmpty} from 'lodash'
import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import {Button, ValidationErrorModal} from '../../../../../_gori/partials/widgets'
import A1_CONSTANTS from '../../core/_const'
import A1BatchService from '../../core/_requests'

type Props = {
  show: boolean
  data?: {payload: any; name_position: string}
  handleClose: () => void
  handleSuccess: () => void
}

const A1BatchDuplicateModal: React.FC<Props> = ({show, data, handleClose, handleSuccess}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()
  const [loading, setLoading] = useState<{exclude: boolean; move: boolean}>({
    exclude: false,
    move: false,
  })

  const handleSubmit = async (type) => {
    const config = {cancelToken: newCancelToken()}

    if (
      type === A1_CONSTANTS.STATUS_ADD_TO_A1_BATCH.ACTION.EXCLUDE &&
      isEmpty(data?.payload?.no_batch)
    ) {
      toast.warning(intl.formatMessage({id: 'NO_ORDERS_TO_ADD_TO_A1_BATCH'}))
      handleClose()
    }

    setLoading((prev) => ({...prev, [type]: true}))
    try {
      const res = await A1BatchService.store({...data?.payload, type: type}, config)
      if (res) {
        toast.success(intl.formatMessage({id: 'ADDED_TO_A1_BATCH_SUCCESSFULLY'}))
        handleSuccess()
      }
    } catch (error: any) {
      if (isCancel(error)) return
      setValidationErrors(error?.response)
    } finally {
      setLoading((prev) => ({...prev, [type]: false}))
    }
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal
        id='gori_modal_a1_batch_duplicated'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-650px h-auto'
        show={show}
        backdrop='static'
        onHide={() => handleClose()}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1 text-truncate'}>
              {intl.formatMessage({id: 'MOVE_SHIPMENT_TO_BATCH'})} "{data?.name_position}"
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <div className='row my-10'>
                <div className='fs-3 mb-1'>
                  <span className='fw-bolder'>
                    {data?.payload?.has_batch?.length} {intl.formatMessage({id: 'ORDERS'})}
                  </span>{' '}
                  {intl.formatMessage({id: 'ARE_ALREADY_IN_A_DIFFERENT_BATCH'})}.
                </div>
                <div className='fs-3 flex-wrap'>
                  {intl.formatMessage({id: 'ARE_YOU_SURE_YOU_WANT_TO_MOVE_THEM_TO'})}{' '}
                  <span className='fw-bolder'>{data?.name_position}</span> ?
                </div>
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-3'
                label={intl.formatMessage({id: 'NO'}) + ', ' + intl.formatMessage({id: 'EXCLUDE'})}
                loadingText={intl.formatMessage({id: 'EXCLUDING'})}
                loading={loading.exclude}
                disabled={loading.exclude}
                event={() => handleSubmit('exclude')}
              />
              <Button
                className='btn btn-primary'
                loading={loading.move}
                disabled={loading.move}
                label={intl.formatMessage({id: 'YES'}) + ', ' + intl.formatMessage({id: 'MOVE'})}
                loadingText={intl.formatMessage({id: 'MOVING'})}
                event={() => handleSubmit('move')}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {A1BatchDuplicateModal}
