import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import {
  Button,
  InputTextFormik,
  SelectFormik,
  ValidationErrorModal,
} from '../../../../_gori/partials/widgets'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {toast} from 'react-toastify'
import {BatchDuplicateModal} from './BatchDuplicateModal'
import BatchService from '../core/_requests'
import {BatchesStatus} from '../core/_const'
import {useAuth} from '../../auth'
import {isEmpty} from 'lodash'
import UseYupValidate from '../../../../_gori/hooks/UseYupValidate'

type Props = {
  show: boolean
  handleClose: () => void
  orders?: any
  reloadTable: () => {}
}

const BatchCreationModal: React.FC<Props> = ({show, handleClose, orders, reloadTable}) => {
  const intl = useIntl()
  const {loadingSwitch} = useAuth()
  const [loading, setLoading] = useState<{first: boolean}>({first: true})
  const {batchesYup} = UseYupValidate()
  const {newCancelToken, isCancel} = useCancelToken()
  const {stringYup} = UseYupValidate()
  const [showBatchDuplicateModal, setShowBatchDuplicateModal] = useState<boolean>(false)
  const [validationErrors, setValidationErrors] = useState<any>()
  const [batches, setBatches] = useState<any>()
  const [latestBatch, setLatestBatch] = useState<any>()
  const [selectedBatch, setSelectedBatch] = useState<any>()
  const [activeBox, setActiveBox] = useState<{
    create: boolean
    select: boolean
  }>({
    create: true,
    select: false,
  })

  const getBatches = useCallback(async () => {
    const config = {
      params: {
        all: true,
        latest: true,
        status: BatchesStatus.OPEN,
      },
      cancelToken: newCancelToken(),
    }
    try {
      const [response, responseBatch] = await Promise.all([
        BatchService.search(config),
        BatchService.get(1, config),
      ])
      setBatches(response.batches)
      setLatestBatch(responseBatch.batch)
    } catch (error) {
      if (isCancel(error)) return
    } finally {
      setLoading((prev) => ({...prev, first: false}))
    }
  }, [isCancel, newCancelToken])

  const optionsBatches = useMemo(() => {
    return (
      batches &&
      Object.entries(batches).map(([key, value]: [any, any]) => ({
        value: {
          id: value?.id,
          name: value?.name,
        },
        label: value?.name,
      }))
    )
  }, [batches])

  const initValidateSchema = useMemo(() => {
    return {
      name: stringYup(255, 'BATCH_NAME'),
    }
  }, [stringYup])

  const selectBatchValidateSchema = useMemo(() => {
    return {
      existing_batch: batchesYup.existingBatch,
    }
  }, [batchesYup])
  const [validateSchema, setValidateSchema] = useState<any>(initValidateSchema)

  const initialValues = useMemo(() => {
    let init = {
      name: '',
      type: 'create',
      bypass: false,
      no_batch: [],
      has_batch: [],
      existing_batch: '',
    }

    if (!isEmpty(latestBatch)) {
      init.name = latestBatch?.id + 100001
    } else {
      init.name = '100001'
    }

    return init
  }, [latestBatch])

  const handleDuplicateBatch = (type: string) => {
    setShowBatchDuplicateModal(false)
    formik.setFieldValue('type', type)
    formik.setFieldValue('bypass', true)
    formik.submitForm()
  }

  const handleCloseForm = () => {
    formik.resetForm()
    setSelectedBatch(undefined)
    setActiveBox({
      create: true,
      select: false,
    })
    handleClose()
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(validateSchema),
    onSubmit: async (values, {setSubmitting}) => {
      if (values.type !== 'close') {
        const noBatch: any = []
        const hasBatch: any = []
        orders.forEach((order) => {
          if (!order.original.batches[0]?.id) {
            noBatch.push(order.original.id)
          } else if (selectedBatch) {
            if (selectedBatch.id !== order.original.batches[0]?.id) {
              hasBatch.push(order.original.id)
            }
          } else {
            hasBatch.push(order.original.id)
          }
        })

        if (isEmpty(selectedBatch)) {
          values.existing_batch = ''
        }

        values.no_batch = noBatch
        values.has_batch = hasBatch
        await formik.setFieldValue('has_batch', hasBatch)

        if (!values.bypass && hasBatch.length > 0) {
          setShowBatchDuplicateModal(true)
          return
        }

        const config = {cancelToken: newCancelToken()}
        try {
          setSubmitting(true)
          await BatchService.store(values, config)
          toast.success(intl.formatMessage({id: 'ADDED_TO_A1_BATCH_SUCCESSFULLY'}))
          handleCloseForm()
          getBatches()
          reloadTable()
        } catch (error: any) {
          if (isCancel(error)) return
          setValidationErrors(error?.response)
        } finally {
          setSubmitting(false)
          formik.resetForm()
          setSelectedBatch(undefined)
        }
      }
    },
  })

  useEffect(() => {
    if (loadingSwitch) return

    getBatches()

    return () => {
      setBatches((prev) => prev)
      setSelectedBatch(undefined)
      setActiveBox({
        create: true,
        select: false,
      })
    }
  }, [getBatches, loadingSwitch])

  return (
    <>
      <Modal
        id='gori_modal_BATCHES_CREATE'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-800px h-auto'
        show={show}
        backdrop='static'
        onHide={() => handleCloseForm()}
      >
        {validationErrors && (
          <ValidationErrorModal
            handleClose={() => {
              setValidationErrors(undefined)
            }}
            response={validationErrors}
          />
        )}
        {showBatchDuplicateModal && (
          <BatchDuplicateModal
            show={showBatchDuplicateModal}
            handleClose={(type) => handleDuplicateBatch(type)}
            batch={{
              ...formik.values,
              ...{existing_batch: isEmpty(selectedBatch) ? null : formik.values.existing_batch},
            }}
          />
        )}

        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'ADD_TO_BATCH'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loading.first ? (
              <Container>
                {Array.from({length: 2}).map((item, index) => (
                  <div className='border rounded mb-7 border-secondary cursor-no-drop' key={index}>
                    <div className='mx-5 my-6 d-flex flex-column'>
                      <div className='fs-2 col-4 mb-2 placeholder placeholder-lg rounded-2 bg-secondary' />
                      <div className='fs-5 col-6 mb-2 placeholder placeholder-lg rounded-2 bg-secondary' />
                      <div className='fs-2 col-2 my-3 placeholder placeholder-lg rounded-2 bg-secondary' />
                      <div className='btn col-9 placeholder placeholder-lg rounded-2 bg-secondary' />
                    </div>
                  </div>
                ))}
              </Container>
            ) : (
              <Container>
                <div className='row my-8'>
                  <div
                    onClick={() => {
                      setSelectedBatch(undefined)
                      setActiveBox({create: true, select: false})
                      setValidateSchema(initValidateSchema)
                      formik.setTouched({
                        name: false,
                      })
                    }}
                    className={`border rounded mb-7 ${
                      activeBox.create ? 'border-primary' : 'border-secondary'
                    }`}
                  >
                    <div className='mx-5 my-6'>
                      <h3 className={`fw-bolder fs-2 ${activeBox.create ? '' : 'text-muted'}`}>
                        {intl.formatMessage({id: 'CREATE_NEW_BATCH'})}
                      </h3>
                      <p className='text-muted'>
                        {intl.formatMessage({id: 'GIVE_A_UNIQUE_NAME_FOR_YOUR_BATCH'})}
                      </p>
                      <InputTextFormik
                        disabled={activeBox.select}
                        className='w-75'
                        labelClassName={`${activeBox.create ? '' : 'text-muted'}`}
                        inputClassName={`${activeBox.create ? '' : 'text-muted'}`}
                        required
                        label={intl.formatMessage({id: 'BATCH_NAME'})}
                        formik={formik}
                        name='name'
                        placeholder='100000'
                        onFocus={() => {
                          setValidateSchema(initValidateSchema)
                          setSelectedBatch(undefined)
                        }}
                      />
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      if (activeBox.create) {
                        setSelectedBatch(formik.values.existing_batch)
                      }
                      setActiveBox({create: false, select: true})
                      setValidateSchema(selectBatchValidateSchema)
                      formik.setTouched({
                        name: false,
                      })
                    }}
                    className={`border rounded ${
                      activeBox.select ? 'border-primary' : 'border-secondary'
                    }`}
                  >
                    <div className='mx-5 my-6'>
                      <h3 className={`fw-bolder fs-2 ${activeBox.select ? '' : 'text-muted'}`}>
                        {intl.formatMessage({id: 'ADD_TO_EXISTING_BATCH'})}
                      </h3>
                      <p className='text-muted'>
                        {intl.formatMessage({id: 'SELECT_A_BATCH_TO_ADD_YOUR_ORDER_TO'})}
                      </p>
                      <SelectFormik
                        disabled={activeBox.create}
                        onChange={(e) => {
                          setValidateSchema(selectBatchValidateSchema)
                          setSelectedBatch(e.value)
                        }}
                        className='w-75'
                        labelClassName={`${activeBox.select ? '' : 'text-muted'}`}
                        label={intl.formatMessage({id: 'SELECT_A_BATCH'})}
                        placeholder={intl.formatMessage({id: 'BATCH'})}
                        options={optionsBatches}
                        formik={formik}
                        name='existing_batch'
                      />
                    </div>
                  </div>
                  <div className='fs-3'></div>
                </div>
              </Container>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-3'
                label={intl.formatMessage({id: 'CANCEL'})}
                event={() => handleCloseForm()}
                disabled={formik.isSubmitting}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'SUBMIT'})}
                loadingText={intl.formatMessage({id: 'SUBMIT'})}
                disabled={loading.first}
                loading={formik.isSubmitting}
                event={() => {
                  formik.setFieldValue('type', 'create')
                  formik.setFieldValue('bypass', false)
                  formik.submitForm()
                }}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {BatchCreationModal}
