import {FC, useRef} from 'react'
import {FormCheckType} from 'react-bootstrap/FormCheck'
import _ from 'lodash'
import clsx from 'clsx'

type Props = {
  type?: FormCheckType
  onChange?: any
  className?: any
  inputClassName?: any
  required?: boolean
  label?: any
  labelClassName?: any
  checked?: any
  disabled?: boolean
}

const InputCheckNoFormik: FC<Props> = ({
  type = 'checkbox',
  onChange,
  className,
  inputClassName,
  label,
  labelClassName,
  checked = false,
  disabled = false,
}) => {
  const {current: id} = useRef(_.uniqueId('input-check'))

  return (
    <div className={className}>
      <div className={`${label && 'form-check'} ${type === 'switch' ? 'form-switch' : ''}`}>
        <input
          id={id}
          aria-label='check'
          className={clsx('form-check-input cursor-pointer', inputClassName)}
          type={type === 'switch' ? 'checkbox' : type}
          checked={checked}
          disabled={disabled}
          {...(onChange ? {onChange: onChange} : {})}
        />
        {label && (
          <label htmlFor={id} className={`form-check-label form-label ${labelClassName}`}>
            {label}
          </label>
        )}
      </div>
    </div>
  )
}

export {InputCheckNoFormik}
