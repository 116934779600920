import {useFormik} from 'formik'
import {useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../_gori/hooks/UseYupValidate'
import {Button, InputTextFormik, ValidationErrorModal} from '../../../../_gori/partials/widgets'
import OrderService from '../core/_requests'

type Props = {
  nameHeader: string
  show: boolean
  handleClose?: any
  handleUpdateFilter?: any
  dataFilters: any
  optionsCurrentFilter: any
  filterKey: 'a1_batch_filter' | 'order_filter'
}

const SaveFilterModal: React.FC<Props> = ({
  nameHeader,
  show,
  handleClose,
  handleUpdateFilter,
  dataFilters,
  optionsCurrentFilter = [],
  filterKey,
}) => {
  const intl = useIntl()
  const {stringYup} = UseYupValidate()
  const {newCancelToken, isCancel} = useCancelToken()
  const [validationErrors, setValidationErrors] = useState<any>()
  const [loading, setLoading] = useState<{create: boolean}>({
    create: false,
  })

  const initValidateSchema = useMemo(() => {
    return {
      options: Yup.array(),
      current_filter: stringYup(255, 'NAME'),
      checkNameRequired: Yup.bool().when(['options', 'current_filter'], {
        is: (options, value) => {
          return options.indexOf(value?.trim()) > -1
        },
        then: Yup.bool().oneOf(
          [true],
          intl.formatMessage(
            {id: 'INPUT_ALREADY_EXISTS'},
            {input: intl.formatMessage({id: 'SAVED_FILTER_NAME'})}
          )
        ),
      }),
    }
  }, [intl, stringYup])

  const formik = useFormik({
    initialValues: {
      options: optionsCurrentFilter.map((item) => item.label.trim()),
      current_filter: '',
      checkNameRequired: false,
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape(initValidateSchema),
    onSubmit: async (values) => {
      let payload = {
        filter_name: values.current_filter,
        filter_key: filterKey,
        filter_payload: {
          ...dataFilters,
        },
      }

      try {
        setLoading((prev) => ({...prev, create: true}))
        await OrderService.createDataSaveFilter(payload, {
          cancelToken: newCancelToken(),
        })
        await handleCloseModal()
        await handleUpdateFilter()
        toast.success(intl.formatMessage({id: 'CREATE_SUCCESSFULLY'}))
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, create: false}))
      }
    },
  })

  const handleCloseModal = () => {
    handleClose()
    formik.resetForm()
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      <Modal show={show} onHide={handleCloseModal} centered>
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title>{nameHeader}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputTextFormik
              labelClassName='form-label fw-bolder text-dark fs-6'
              label={intl.formatMessage({id: 'NAME'})}
              size='lg'
              formik={formik}
              name='current_filter'
              required
            />
            <div className='text-danger my-2'>{formik.errors.checkNameRequired}</div>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
                disabled={loading.create}
              />
              <Button
                className={'btn btn-primary'}
                label={intl.formatMessage({id: 'SAVE'})}
                loadingText={intl.formatMessage({id: 'SAVE'})}
                event={formik.handleSubmit}
                loading={loading.create}
                disabled={loading.create}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {SaveFilterModal}
