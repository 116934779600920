import FileSaver from 'file-saver'
import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import {useSearchParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {DEFAULT_PAGE} from '../../../../../_gori/constants'
import {fileHelper, KTSVG, toAbsoluteUrl} from '../../../../../_gori/helpers'
import {Button} from '../../../../../_gori/partials/widgets'
import {AddToA1BatchModal} from '../../../a1'
import A1_CONSTANTS from '../../core/_const'

type Props = {
  show: boolean
  handleClose: () => void
  handleSuccess: () => void
}

const UploadA1Batch: React.FC<Props> = ({show, handleClose, handleSuccess}) => {
  const intl = useIntl()
  const [searchParams, setSearchParams] = useSearchParams()
  const [showModal, setShowModal] = useState<{add_to_A1_batch: boolean}>({add_to_A1_batch: false})
  const [fileInput, setFileInput] = useState<any>(null)

  const handleCloseModal = () => {
    setFileInput(null)
    handleClose()
  }

  const setFile = async (e: any) => {
    const file = e.target.files[0]
    if (!file) return

    if (!A1_CONSTANTS.UPLOAD_CONFIG.SUPPORTED_FORMATS.includes(file.type)) {
      return toast.error(intl.formatMessage({id: 'INVALID_FILE_TYPE_CSV_ONLY'}))
    }

    const {row} = await fileHelper.checkRowHaveData(file)
    if (row > A1_CONSTANTS.UPLOAD_CONFIG.MAX_UPLOAD) {
      return toast.error(
        intl.formatMessage(
          {id: 'CANNOT_UPLOAD_MORE_THAN_INPUT_ITEMS'},
          {input: A1_CONSTANTS.UPLOAD_CONFIG.MAX_UPLOAD}
        )
      )
    }

    if (file.size > A1_CONSTANTS.UPLOAD_CONFIG.SIZE) {
      return toast.warning(
        intl.formatMessage(
          {id: 'FILE_INPUT_ERROR_UPLOADING_PLEASE_UPLOAD_FILES_SMALLER_THAN_8MB'},
          {input: file.name}
        )
      )
    }

    setFileInput(file)
    e.target.value = null
  }

  const handleUploadSuccess = () => {
    if (!(searchParams.get('search_text') === '' && searchParams.get('page') === DEFAULT_PAGE)) {
      searchParams.delete('search_text')
      searchParams.set('page', DEFAULT_PAGE)
      setSearchParams(searchParams)
    }
    handleSuccess()
    handleCloseModal()
  }

  return (
    <>
      {showModal.add_to_A1_batch && (
        <AddToA1BatchModal
          show={showModal.add_to_A1_batch}
          handleClose={() => {
            setShowModal((prev) => ({...prev, add_to_A1_batch: false}))
            handleCloseModal()
          }}
          file={fileInput}
          reloadTable={() => handleUploadSuccess()}
        />
      )}
      <Modal
        id='gori_modal_upload_a1_batch'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-650px h-auto'
        show={show && !showModal.add_to_A1_batch}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'UPLOAD_A1_BATCH'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <Container>
              <Button
                event={() =>
                  FileSaver.saveAs(
                    toAbsoluteUrl('/media/gori/a1/A1_Batch_Upload_Template.csv'),
                    'A1 Batch Upload Template.csv'
                  )
                }
                className='btn btn-light btn-sm fw-bolder rounded-2'
                label={intl.formatMessage({id: 'A1_BATCH_UPLOAD_TEMPLATE'})}
                children={<KTSVG path='/media/gori/batches/file_download.svg' />}
              />
              <div className='dropzone bg-light-primary border-primary border-2 mt-5'>
                <div className='d-flex justify-content-center my-4'>
                  <div>
                    <div className='fs-4 fw-bold text-dark mb-1'>
                      {intl.formatMessage({id: 'DRAG_AND_DROP_HERE_OR'})}
                      <span className='ms-2 text-primary'>
                        {intl.formatMessage({id: 'BROWSE_FILES'})}
                      </span>
                    </div>
                    {!!fileInput ? (
                      <span className='fw-bold text-primary text-decoration fs-5 mt-2'>
                        {fileInput.name}
                      </span>
                    ) : (
                      <Button
                        className='btn btn-secondary mt-4'
                        label={intl.formatMessage({id: 'UPLOAD_FILE'})}
                      />
                    )}
                  </div>
                  <input
                    type='file'
                    id='file-upload'
                    name='file-img'
                    onChange={(e: any) => setFile(e)}
                    className='dropzone__upload mt-n10 cursor-pointer'
                    accept={A1_CONSTANTS.UPLOAD_CONFIG.SUPPORTED_FORMATS.join(',')}
                  />
                </div>
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                loadingText={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'SUBMIT'})}
                loadingText={intl.formatMessage({id: 'SUBMIT'})}
                disabled={!fileInput}
                event={() => {
                  setShowModal((prev) => ({...prev, add_to_A1_batch: true}))
                }}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {UploadA1Batch}
