import clsx from 'clsx'
import {useFormik} from 'formik'
import moment from 'moment'
import React, {useMemo, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {yupHelper} from '../../../../../_gori/helpers'
import useCancelToken from '../../../../../_gori/hooks/UseCancelToken'
import UseYupValidate from '../../../../../_gori/hooks/UseYupValidate'
import {
  Button,
  InputDate,
  InputTextFormik,
  SelectFormik,
  ValidationErrorModal,
} from '../../../../../_gori/partials/widgets'
import {useA1BatchesProvider} from '../../../../../_gori/providers/A1BatchesProvider'
import A1_CONSTANTS from '../../core/_const'
import A1BatchService from '../../core/_requests'
import {A1ServiceModal} from './A1ServiceModal'

type Props = {
  show: boolean
  handleClose: () => void
  handleSuccess: () => void
}

const JourneyInformationModal: React.FC<Props> = ({show, handleClose, handleSuccess}) => {
  const intl = useIntl()
  const yup = yupHelper(intl)
  const {newCancelToken, isCancel} = useCancelToken()
  const {stringYup, dateYup} = UseYupValidate()
  const {a1Batch, journeyInfo} = useA1BatchesProvider()

  const [validationErrors, setValidationErrors] = useState<any>()
  const [loading, setLoading] = useState<{update: boolean}>({update: false})
  const [hasShow, setHasShow] = useState<{a1Service: boolean}>({a1Service: false})

  const handleCloseModal = () => {
    handleClose()
  }

  const initValidateSchema = useMemo(() => {
    return {
      from: stringYup(255, 'FROM'),
      to: stringYup(255, 'TO'),
      estimated_pickup_at: dateYup('PICK_UP_DATE', moment().startOf('day').format()),
      services: stringYup(255, 'A1_SERVICE'),
      pallets: yup.number({
        name: 'PALLETS',
        required: false,
        trim: true,
        min: {
          value: 0.1,
          label: 0,
        },
        max: {
          value: 9999.99,
          label: '99,99.99',
        },
      }),
      parcels: yup.number({
        name: 'PARCELS',
        required: false,
        trim: true,
        min: {
          value: 0.1,
          label: 0,
        },
        max: {
          value: 9999.99,
          label: '99,99.99',
        },
      }),
      weight: yup.number({
        name: 'KGS',
        required: false,
        trim: true,
        min: {
          value: 0.1,
          label: 0,
        },
        max: {
          value: 999.99,
          label: '999.99',
        },
      }),
    }
  }, [dateYup, stringYup, yup])

  const initialValues = useMemo(() => {
    return {
      from: journeyInfo?.from || null,
      to: journeyInfo?.to || null,
      estimated_pickup_at: journeyInfo?.estimated_pickup_at || moment(),
      services: journeyInfo?.services || null,
      pallets: journeyInfo?.pallets || null,
      parcels: journeyInfo?.parcels || null,
      weight: journeyInfo?.weight || null,
    }
  }, [
    journeyInfo?.to,
    journeyInfo?.weight,
    journeyInfo?.from,
    journeyInfo?.pallets,
    journeyInfo?.parcels,
    journeyInfo?.estimated_pickup_at,
    journeyInfo?.services,
  ])

  const formik = useFormik({
    initialValues,
    enableReinitialize: false,
    validationSchema: Yup.object().shape(initValidateSchema),
    onSubmit: async (values) => {
      const config = {cancelToken: newCancelToken()}
      try {
        setLoading((prev) => ({...prev, update: true}))
        const res = await A1BatchService.updateA1Batch(
          a1Batch.id,
          {...values, epu_at: values?.estimated_pickup_at},
          config
        )
        if (res) {
          handleSuccess()
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoading((prev) => ({...prev, update: false}))
      }
    },
  })

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}
      {hasShow.a1Service && (
        <A1ServiceModal
          show={show}
          handleClose={() => {
            setHasShow((prev) => ({...prev, a1Service: false}))
          }}
        />
      )}
      <Modal
        id='gori_modal_journey_information_modal'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-1250px h-auto'
        show={show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'JOURNEY_INFORMATION'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={clsx('vh-75 scroll-y', {'cursor-no-drop': loading.update})}>
            <Container className={clsx({'pe-none opacity-75': loading.update})}>
              <div className='border-bottom'>
                <h3 className='required'> {intl.formatMessage({id: 'PICK_UP_INFORMATION'})}</h3>
                <div className='d-flex flex-column flex-md-row my-8 gap-6'>
                  <div className='col-12 col-md-4'>
                    <div className='d-flex'>
                      <SelectFormik
                        className='col-8'
                        labelClassName='col-4 col-form-label fs-5 fw-bolder text-dark'
                        label={intl.formatMessage({id: 'FROM'})}
                        placeholder={intl.formatMessage({id: 'ORIGIN'})}
                        options={A1_CONSTANTS.OPTIONS_ORIGIN}
                        formik={formik}
                        name='from'
                        emptyDefault={false}
                      />
                    </div>
                    <div className='d-flex mt-4'>
                      <SelectFormik
                        className='col-8'
                        labelClassName='col-4 col-form-label fs-5 fw-bolder text-dark'
                        label={intl.formatMessage({id: 'TO'})}
                        placeholder={intl.formatMessage({id: 'DESTINATION'})}
                        options={A1_CONSTANTS.OPTIONS_DESTINATION}
                        formik={formik}
                        name='to'
                        emptyDefault={false}
                      />
                    </div>
                  </div>
                  <div className='d-flex col-12 col-md-4 mt-4 mt-md-0'>
                    <SelectFormik
                      className='col-8'
                      labelClassName='col-4 col-form-label fs-5 fw-bolder text-dark'
                      label={intl.formatMessage({id: 'A1_SERVICE'})}
                      placeholder={intl.formatMessage({id: 'SELECT'})}
                      options={Object.values(A1_CONSTANTS.OPTIONS_A1_SERVICE)}
                      formik={formik}
                      name='services'
                      emptyDefault={false}
                      onChange={(selected) => {
                        if (selected.value === A1_CONSTANTS.OPTIONS_A1_SERVICE.ALL_IN_ONE.value) {
                          setHasShow((prev) => ({...prev, a1Service: true}))
                        }
                      }}
                      hasUseIntl={true}
                    />
                  </div>
                  <div className='d-flex col-12 col-md-4 mt-4 mt-md-0'>
                    <InputDate
                      className={'col-8'}
                      label={intl.formatMessage({id: 'PICK_UP'})}
                      labelClassName='col-4 col-form-label fs-5 fw-bolder text-dark'
                      name='estimated_pickup_at'
                      formik={formik}
                      min={moment()}
                      views={['year', 'month', 'day', 'hours', 'minutes']}
                    />
                  </div>
                </div>
              </div>
              <div className='border-bottom mt-10'>
                <h3>{intl.formatMessage({id: 'SHIPMENT_DETAIL'})}</h3>
                <div className='d-flex flex-column flex-md-row my-8 gap-6'>
                  <div className='d-flex col-12 col-md-4 mt-4 mt-md-0'>
                    <InputTextFormik
                      className='col-8'
                      label={`# ${intl.formatMessage({id: 'OF_PALLETS'})}`}
                      labelClassName='col-4 col-form-label fs-5 fw-bolder text-dark'
                      formik={formik}
                      name='pallets'
                    />
                  </div>
                  <div className='d-flex col-12 col-md-4 mt-4 mt-md-0'>
                    <InputTextFormik
                      className='col-8'
                      label={`# ${intl.formatMessage({id: 'OF_PARCELS'})}`}
                      labelClassName='col-4 col-form-label fs-5 fw-bolder text-dark'
                      formik={formik}
                      name='parcels'
                    />
                  </div>
                  <div className='d-flex col-12 col-md-4 mt-4 mt-md-0'>
                    <InputTextFormik
                      className='col-8'
                      label={intl.formatMessage({id: 'KGS'})}
                      labelClassName='col-4 col-form-label fs-5 fw-bolder text-dark'
                      formik={formik}
                      name='weight'
                    />
                  </div>
                </div>
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CANCEL'})}
                event={handleCloseModal}
              />
              <Button
                className='btn btn-primary'
                label={intl.formatMessage({id: 'UPDATE'})}
                loadingText={intl.formatMessage({id: 'UPDATE'})}
                disabled={loading.update}
                event={formik.handleSubmit}
                loading={loading.update}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {JourneyInformationModal}
