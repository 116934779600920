import {useFormik} from 'formik'
import React from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import {Button} from '../../../../../_gori/partials/widgets'
import {InputCheckboxFormik} from '../../../../../_gori/partials/widgets/form/InputCheckboxFormik'

type Props = {
  show: boolean
  handleClose: () => void
}

const A1ServiceModal: React.FC<Props> = ({show, handleClose}) => {
  const intl = useIntl()

  const formik = useFormik({
    initialValues: {
      pick_up_service: true,
      freight: true,
      customs: true,
    },
    enableReinitialize: false,
    onSubmit: () => {},
  })

  const checkboxRadio = (label, name) => {
    return (
      <div className='d-flex'>
        <div className='col-5 col-form-label fs-5 text-truncate me-4'>
          {intl.formatMessage({id: label})}
        </div>
        <div className='d-flex align-items-center mt-2 pe-none'>
          <div>
            <InputCheckboxFormik
              formik={formik}
              type='radio'
              label={intl.formatMessage({id: 'YES'})}
              className={'pe-4'}
              checked={formik.values[name]}
              onClick={() => formik.setFieldValue(name, true)}
            />
          </div>
          <div>
            <InputCheckboxFormik
              formik={formik}
              type='radio'
              label={intl.formatMessage({id: 'NO'})}
              checked={!formik.values[name]}
              onClick={() => formik.setFieldValue(name, false)}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Modal
        id='gori_modal_a1_service_modal'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-500px h-auto'
        show={show}
        backdrop='static'
        onHide={handleClose}
      >
        <div className='modal-content'>
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: 'A1_SERVICE'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='vh-75 scroll-y'>
            <Container>
              {checkboxRadio('PICKUP_SERVICE', 'pick_up_service')}
              {checkboxRadio('FREIGHT', 'freight')}
              {checkboxRadio('CUSTOMS', 'customs')}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <Button
                className='btn btn-secondary me-2'
                label={intl.formatMessage({id: 'CLOSE'})}
                event={handleClose}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {A1ServiceModal}
