import clsx from 'clsx'
import {isEmpty} from 'lodash'
import React, {useCallback, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {convertUserTimeZone, KTSVG} from '../../../../../_gori/helpers'
import {useA1BatchesProvider} from '../../../../../_gori/providers/A1BatchesProvider'
import {useAuth} from '../../../auth'
import A1_CONSTANTS from '../../core/_const'

type Props = {
  handleEditJourneyInfo: () => void
}

const JourneyInfoCard: React.FC<Props> = ({handleEditJourneyInfo}) => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {a1Batch, journeyInfo, journeyStatus} = useA1BatchesProvider()

  // const journeyInfoCustom = [
  //   {
  //     id: 5,
  //     order: 0,
  //     type: 'origin',
  //     airport: 'SZX',
  //     flight_number: 'GR 013',
  //     estimated_departure_at: null,
  //     estimated_arrival_at: null,
  //     actual_departure_at: '2024-11-21 07:17:27',
  //     actual_arrival_at: null,
  //   },
  //   {
  //     id: 6,
  //     order: 1,
  //     type: 'layover',
  //     airport: 'JOI',
  //     flight_number: 'GR 014',
  //     estimated_departure_at: null,
  //     estimated_arrival_at: null,
  //     actual_departure_at: '2024-11-22 09:20:27',
  //     actual_arrival_at: '2024-11-22 07:17:27',
  //   },
  //   {
  //     id: 7,
  //     order: 2,
  //     type: 'layover',
  //     airport: 'KJS',
  //     flight_number: 'GR 015',
  //     estimated_departure_at: null,
  //     estimated_arrival_at: null,
  //     actual_departure_at: '2024-11-23 08:20:27',
  //     actual_arrival_at: '2024-11-23 06:18:27',
  //   },
  //   {
  //     id: 8,
  //     order: 3,
  //     type: 'destination',
  //     airport: 'LAX',
  //     flight_number: null,
  //     estimated_departure_at: null,
  //     estimated_arrival_at: null,
  //     actual_departure_at: null,
  //     actual_arrival_at: '2024-11-24 06:24:27',
  //   },
  // ]

  const formattedDate = useCallback(
    (date) => (date ? convertUserTimeZone(date, currentUser, A1_CONSTANTS.DATE_FORMAT) : null),
    [currentUser]
  )

  return (
    <div className='card me-xxl-4 h-100'>
      <div className='card-header border-0 fw-bolder d-flex justify-content-between align-items-center'>
        <span className='fs-3 required'>{intl.formatMessage({id: 'JOURNEY_INFO'})}</span>
        {journeyStatus.statusPrimary === null && a1Batch.readied && (
          <div
            className='btn btn-sm btn-light-primary rounded-pill'
            onClick={handleEditJourneyInfo}
          >
            {intl.formatMessage({id: 'EDIT'})}
          </div>
        )}
      </div>
      <div className='card-body bg-light rounded-bottom d-flex flex-column flex-md-row'>
        <div className='col-12 col-md-6'>
          <div className='d-flex pe-md-6 text-primary fs-5 fs-xxl-7 text-center fw-bolder'>
            <div className='col-4'>{intl.formatMessage({id: 'JOURNEY'})}</div>
            <div className='col-4'>{intl.formatMessage({id: 'FLIGHT_NUMBER'})}</div>
            <div className='col-4'>ATD &#8226; ATA</div>
          </div>
          <div className='d-flex flex-column min-h-150px mh-175px scroll-y pe-md-6 mt-4'>
            {!isEmpty(a1Batch?.flights) &&
              a1Batch?.flights?.map((flight: any, index) => {
                return (
                  <div
                    className={clsx('d-flex flex-column', {
                      'flex-column-fluid': index !== a1Batch?.flights?.length - 1,
                    })}
                  >
                    <div className='col-4 text-center'>
                      <div className='fs-1 fw-boldest'>{flight?.airport}</div>
                    </div>
                    {index !== a1Batch?.flights?.length - 1 && (
                      <div className='d-flex flex-fill'>
                        <div className='col-4 d-flex flex-column justify-content-center align-items-center'>
                          <div className='border-dashed border-gray-600 border-2 flex-fill h-100' />
                        </div>
                        <div className='col-4 d-flex align-items-center justify-content-center fw-bolder gap-2'>
                          <KTSVG
                            path='/media/gori/a1/flight.svg'
                            className='svg-icon-1 text-gray-700'
                          />
                          <span className='fs-4 fs-xxl-6'>{flight?.flight_number}</span>
                        </div>
                        <div className='col-4 d-flex flex-column justify-content-center text-center fs-6 fs-xxl-8 fw-bold gap-2 text-gray-700'>
                          <span>{formattedDate(flight?.actual_departure_at)}</span>
                          <span>
                            {formattedDate(a1Batch?.flights[index + 1]?.actual_arrival_at)}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
          </div>
        </div>
        <span className='border border-secondary my-8 my-md-n8' />
        <div className='col-12 col-md-6 ps-6 fs-5 fs-xxl-7 fw-bold'>
          <div className='col-12 d-flex border-bottom border-secondary'>
            <div className='col-3'>{intl.formatMessage({id: 'A1_SERVICE'})}</div>
            <div>
              {journeyInfo.services === 'all_in_one' && (
                <>
                  <span className='fs-5 fs-xxl-7 fw-boldest'>A1</span>{' '}
                  <span className='fs-6 fs-xxl-8 fw-bolder'>
                    ({intl.formatMessage({id: 'ALL_IN_ONE'})})
                  </span>
                </>
              )}
            </div>
          </div>
          <div className='d-flex mt-10'>
            <div className='col-6 d-flex border-bottom border-secondary'>
              <div className='col-6'>MAWB</div>
              <div className='fw-boldest'>{journeyInfo?.mawb}</div>
            </div>
            <div className='col-6 d-flex border-bottom border-secondary'>
              <div className='col-6'>{intl.formatMessage({id: 'PALLETS'})}</div>
              <div className='fw-boldest'>{journeyInfo.pallets}</div>
            </div>
          </div>
          <div className='d-flex mt-10'>
            <div className='col-6 d-flex border-bottom border-secondary'>
              <div className='col-6'>{intl.formatMessage({id: 'PARCELS'})}</div>
              <div className='fw-boldest'>{journeyInfo.parcels}</div>
            </div>
            <div className='col-6 d-flex border-bottom border-secondary'>
              <div className='col-6'>{intl.formatMessage({id: 'KGS'})}</div>
              <div className='fw-boldest'>{journeyInfo.weight}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {JourneyInfoCard}
