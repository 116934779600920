import clsx from 'clsx'
import {useFormik} from 'formik'
import {cloneDeep, isEqual, omit, includes, find} from 'lodash'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import useCancelToken from '../../../../_gori/hooks/UseCancelToken'
import {
  Button,
  InputCheckNoFormik,
  InputTextFormik,
  SelectFormik,
  ValidationErrorModal,
} from '../../../../_gori/partials/widgets'
import SettingsService from '../../settings/core/_requests'
import {OrdersConfig} from '../core/_const'
import OrderService from '../core/_requests'
import {AddressValidationModal} from './AddressValidationModal'
import {OPTION_COUNTRIES} from '../../../../_gori/constants'
import {AutocompleteAddress} from '../../common/components/AutocompleteAddress'

type Props = {
  data: {show: boolean; name: 'from' | 'to'; data: Object; validate: Object}
  handleSave: any
  handleClose: () => void
  disabled?: boolean
  idEdit?: number
  autoSave?: boolean
  labelModal?: string
  hasReturn?: boolean
}

const FromOrToModal: React.FC<Props> = ({
  data,
  handleSave,
  handleClose,
  disabled,
  idEdit = undefined,
  autoSave = false,
  labelModal,
  hasReturn = false,
}) => {
  const intl = useIntl()
  const {newCancelToken, isCancel} = useCancelToken()
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  const [loadingValidate, setLoadingValidate] = useState<boolean>(false)
  const [action, setAction] = useState<'save' | 'validate' | undefined>()
  const [dataSuggest, setDataSuggest] = useState<any>()
  const [showValidationModal, setShowValidationModal] = useState(false)
  const [validationErrors, setValidationErrors] = useState<any>()
  const valueVerified = useRef<any>()

  const handleUseSuggested = (key) => {
    valueVerified.current = OrdersConfig.VALIDATE_ADDRESS.reduce((initValue, current) => {
      initValue[current] = dataSuggest[current]
      return initValue
    }, {})

    const updateValue = OrdersConfig.VALIDATE_ADDRESS.reduce((initValue, current) => {
      initValue[`${key}_${current}`] = dataSuggest[current]
      return initValue
    }, {})
    updateValue[`${data.name}_is_verified`] = true

    formik.setValues({...formik.values, ...updateValue})
  }

  const handleCloseModal = () => {
    handleClose()
    setAction(undefined)
    formik.resetForm()
  }

  const validateSchema = Yup.object().shape(
    Object.entries(cloneDeep(data.validate)).reduce((validate, [key, value]) => {
      validate[key] = value
      return validate
    }, {}),
    [[`${data.name}_first_name`, `${data.name}_company`]]
  )

  const initialValues = useMemo(() => {
    return cloneDeep(data.data)
  }, [data.data])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validateSchema,
    onSubmit: async (values) => {
      if (action === 'validate') {
        handleValidateAddress(values)
      } else if (autoSave && action === 'save') {
        handleSaveAddress(values)
      } else {
        handleSave(data.name, values)
      }
    },
  })

  const handleSaveAddress = useCallback(
    async (values) => {
      setLoadingSave(true)
      const payload = Object.entries(OrdersConfig.SHIPPING).reduce((valueTotal, [key, value]) => {
        switch (value.value) {
          case OrdersConfig.SHIPPING.SAVE_ADDRESS.value:
            valueTotal[value.value] = hasReturn ? `return_${data.name}` : data.name
            break
          case OrdersConfig.SHIPPING.RESIDENTIAL.value:
          case OrdersConfig.SHIPPING.VERIFIED.value:
            valueTotal[value.value] = values[`${data.name}_${value.value}`] ? 1 : 0
            break
          default:
            valueTotal[value.value] = values[`${data.name}_${value.value}`]
            break
        }

        return valueTotal
      }, {})

      try {
        let res
        if (idEdit) {
          res = await SettingsService.editAddress(
            {id: idEdit, ...payload},
            {cancelToken: newCancelToken()}
          )
        } else {
          res = await SettingsService.saveAddress(payload, {cancelToken: newCancelToken()})
        }

        if (res) {
          toast.success(
            intl.formatMessage({id: idEdit ? 'UPDATED_SUCCESSFULLY' : 'CREATED_SUCCESSFULLY'})
          )
          handleSave(data.name, values)
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoadingSave(false)
      }
    },
    [data.name, handleSave, hasReturn, idEdit, intl, isCancel, newCancelToken]
  )

  const handleValidateAddress = useCallback(
    async (values) => {
      setLoadingValidate(true)
      try {
        const valuePayload = OrdersConfig.VALIDATE_ADDRESS.reduce((valuePayload, current) => {
          valuePayload[current] = values[`${data.name}_${current}`]
          return valuePayload
        }, {})
        valueVerified.current = valuePayload

        const res = await OrderService.validateAddress(
          {
            address: valuePayload,
          },
          {cancelToken: newCancelToken()}
        )

        if (res) {
          const valueSuggest = OrdersConfig.VALIDATE_ADDRESS.reduce((initValue, current) => {
            initValue[current] = res[current]
            return initValue
          }, {})

          formik.setFieldValue(
            `${data.name}_is_verified`,
            isEqual(valuePayload, valueSuggest) &&
              res.verifications.delivery.success &&
              res.verifications.zip4.success
          )

          setDataSuggest(res)
          setShowValidationModal(true)
        }
      } catch (error: any) {
        if (isCancel(error)) return
        setValidationErrors(error?.response)
      } finally {
        setLoadingValidate(false)
        setAction(undefined)
      }
    },
    [data.name, formik, isCancel, newCancelToken]
  )

  useEffect(() => {
    if (formik.values?.[`${data.name}_is_verified`] === true) {
      const valueInit = OrdersConfig.VALIDATE_ADDRESS.reduce((valueInit, current) => {
        valueInit[current] = data.data[`${data.name}_${current}`]
        return valueInit
      }, {})

      const valueCurrent = OrdersConfig.VALIDATE_ADDRESS.reduce((valueCurrent, current) => {
        valueCurrent[current] = formik.values[`${data.name}_${current}`]
        return valueCurrent
      }, {})

      if (
        (!isEqual(valueCurrent, valueInit) && !valueVerified.current) ||
        (!isEqual(valueCurrent, valueVerified.current) && valueVerified.current)
      ) {
        formik.setFieldValue(`${data.name}_is_verified`, false)
      }
    }
  }, [data.data, data.name, formik, showValidationModal])

  const selectedPlace = (placeDetails) => {
    Object.entries(omit(OrdersConfig.SHIPPING, ['RESIDENTIAL', 'VERIFIED'])).forEach(
      ([key, value]: [any, any]) => {
        switch (value.value) {
          case OrdersConfig.SHIPPING.CITY.value:
            let city = find(placeDetails.address_components, (item) => {
              return includes(item?.types, 'locality')
            })
            formik.setFieldValue(
              formik.getFieldProps(`${data.name}_${value.value}`).name,
              city?.long_name ?? null
            )
            break
          case OrdersConfig.SHIPPING.STATE.value:
            let state = find(placeDetails.address_components, (item) => {
              return includes(item?.types, 'administrative_area_level_1')
            })
            formik.setFieldValue(
              formik.getFieldProps(`${data.name}_${value.value}`).name,
              state?.short_name ?? null
            )
            break
          case OrdersConfig.SHIPPING.COUNTRY.value:
            let country = find(placeDetails.address_components, (item) => {
              return includes(item?.types, 'country')
            })
            let checkExistsCountry
            if (country?.short_name) {
              checkExistsCountry = OPTION_COUNTRIES.find(
                (item) => item.value === country?.short_name
              )
            }
            formik.setFieldValue(
              formik.getFieldProps(`${data.name}_${value.value}`).name,
              checkExistsCountry ? country?.short_name : ''
            )
            break
          case OrdersConfig.SHIPPING.ZIP_CODE.value:
            let zipCode = find(placeDetails.address_components, (item) => {
              return includes(item?.types, 'postal_code')
            })
            let suffix = find(placeDetails.address_components, (item) => {
              return includes(item?.types, 'postal_code_suffix')
            })
            if (zipCode?.long_name) {
              zipCode = zipCode?.long_name + (suffix?.long_name ? `-${suffix?.long_name}` : '')
            } else {
              zipCode = ''
            }
            formik.setFieldValue(formik.getFieldProps(`${data.name}_${value.value}`).name, zipCode)
            break
        }
      }
    )
  }

  const renderField = (arr) => {
    return arr.map(([key, value]: [any, any], index) => {
      switch (value.value) {
        case OrdersConfig.SHIPPING.ADDRESS.value:
          return (
            <div className='mb-8 d-flex flex-fill' key={index}>
              <AutocompleteAddress
                formik={formik}
                label={intl.formatMessage({id: OrdersConfig.SHIPPING[key].label})}
                name={`${data.name}_${value.value}`}
                onSelected={(selected) => {
                  selectedPlace(selected)
                }}
                className='col-8 col-sm-9'
                labelClassName='col-4 col-sm-3 col-form-label'
              />
            </div>
          )
        case OrdersConfig.SHIPPING.COUNTRY.value:
          return (
            <div className='mb-8 d-flex' key={index}>
              <SelectFormik
                className='col-8 col-sm-9'
                labelClassName='col-4 col-sm-3 col-form-label'
                label={intl.formatMessage({id: OrdersConfig.SHIPPING[key].label})}
                required
                options={OPTION_COUNTRIES}
                formik={formik}
                name={`${data.name}_${value.value}`}
              />
            </div>
          )
        default:
          return (
            <>
              {value.value === OrdersConfig.SHIPPING.ZIP_CODE.value && (
                <div className='col-md-6'></div>
              )}
              <div className='mb-8 d-flex' key={index}>
                <InputTextFormik
                  className='col-8 col-sm-9'
                  labelClassName='col-4 col-sm-3 col-form-label'
                  required={value?.required || false}
                  label={intl.formatMessage({id: OrdersConfig.SHIPPING[key].label})}
                  formik={formik}
                  name={`${data.name}_${value.value}`}
                />
              </div>
            </>
          )
      }
    })
  }

  return (
    <>
      {validationErrors && (
        <ValidationErrorModal
          handleClose={() => {
            setValidationErrors(undefined)
          }}
          response={validationErrors}
        />
      )}

      {showValidationModal && (
        <AddressValidationModal
          show={showValidationModal}
          handleCloseValidation={() => {
            setShowValidationModal(false)
          }}
          handleUseSuggested={handleUseSuggested}
          dataSuggest={{[data.name]: dataSuggest}}
          dataOriginal={{[data.name]: formik.values}}
          fromOrTo={data.name}
        />
      )}

      <Modal
        id='gori_modal_from_to_order'
        tabIndex={-1}
        aria-hidden='true'
        centered
        dialogClassName='mw-1000px h-auto'
        show={data?.show}
        backdrop='static'
        onHide={handleCloseModal}
      >
        <div
          className={clsx('modal-content', {
            'cursor-no-drop': disabled,
          })}
        >
          <Modal.Header closeButton>
            <Modal.Title bsPrefix={'fw-bolder fs-1'}>
              {intl.formatMessage({id: labelModal ? labelModal : 'SHIPPING_ADDRESS'})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container
              className={clsx({
                'pe-none': disabled,
              })}
            >
              <div className='row d-flex'>
                <div className='d-flex justify-content-between pb-3'>
                  <h3 className='fw-bolder fs-2'>
                    {intl.formatMessage({
                      id: hasReturn
                        ? `RETURN_${data.name.toUpperCase()}`
                        : `SHIP_${data.name.toUpperCase()}`,
                    })}
                  </h3>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='row text-primary fw-bolder my-6'>
                    <div className='fs-5 col-md-6'>{intl.formatMessage({id: 'CONTACT_INFO'})}</div>
                  </div>
                  {renderField(
                    Object.entries(
                      omit(OrdersConfig.SHIPPING, ['SAVE_ADDRESS', 'RESIDENTIAL', 'VERIFIED'])
                    ).splice(0, 5)
                  )}
                </div>
                <div className='col-md-6'>
                  <div className='row text-primary fw-bolder my-6'>
                    <div className='fs-5 d-flex justify-content-between'>
                      <div>{intl.formatMessage({id: 'ADDRESS_INFO'})}</div>
                      {data.name === 'to' && (
                        <div>
                          <InputCheckNoFormik
                            checked={formik.getFieldProps('to_is_residential').value}
                            onChange={() =>
                              formik.setFieldValue(
                                formik.getFieldProps('to_is_residential').name,
                                !formik.getFieldProps('to_is_residential').value
                              )
                            }
                            label={intl.formatMessage({id: 'RESIDENTIAL'})}
                            labelClassName='text-gray-600 fw-bolder'
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {renderField(
                    Object.entries(
                      omit(OrdersConfig.SHIPPING, ['SAVE_ADDRESS', 'RESIDENTIAL', 'VERIFIED'])
                    ).splice(5)
                  )}
                </div>
              </div>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div
              className={clsx('d-flex justify-content-end', {
                'pe-none': disabled,
              })}
            >
              <Button
                className='btn btn-link text-decoration-underline fw-bolder me-4'
                label={intl.formatMessage({id: 'VERIFY_ADDRESS'})}
                loadingText={intl.formatMessage({id: 'VERIFY_ADDRESS'})}
                loading={loadingValidate}
                disabled={loadingValidate}
                event={() => {
                  setAction('validate')
                  formik.submitForm()
                }}
              />
              <Button
                className='btn btn-primary'
                label={idEdit ? intl.formatMessage({id: 'SAVE'}) : intl.formatMessage({id: 'SAVE'})}
                loadingText={
                  idEdit ? intl.formatMessage({id: 'SAVE'}) : intl.formatMessage({id: 'SAVE'})
                }
                loading={loadingSave}
                disabled={loadingSave}
                event={() => {
                  setAction('save')
                  formik.submitForm()
                }}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  )
}

export {FromOrToModal}
