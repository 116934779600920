import {find, isEmpty, omit} from 'lodash'
import React, {useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {convertUserTimeZone, KTSVG} from '../../../../../_gori/helpers'
import {useA1BatchesProvider} from '../../../../../_gori/providers/A1BatchesProvider'
import {A1HoldOrderModal} from '../../../a1'
import {useAuth} from '../../../auth'
import A1_CONSTANTS from '../../core/_const'

type Props = {}

const StatusRow = ({label}) => (
  <div className='col-7-equal min-w-100px'>
    <div className='px-2'>{label}</div>
  </div>
)

const DataRow = ({value}) => {
  const intl = useIntl()
  const [hasShow, setHasShow] = useState<{holdModal: boolean}>({holdModal: false})

  return (
    <>
      {hasShow.holdModal && (
        <A1HoldOrderModal
          show={hasShow.holdModal}
          handleClose={() => {
            setHasShow((prev) => ({...prev, holdModal: false}))
          }}
        />
      )}
      <div className='col-7-equal min-w-100px'>
        {value.map(({date, time, hold = 0}, idx) => (
          <div className='px-2 fw-bold' key={idx}>
            {date}
            {hold > 0 && (
              <div
                className='bg-light-danger bg-hover-light-info p-2 rounded-pill text-decoration-underline cursor-pointer'
                onClick={() => setHasShow((prev) => ({...prev, holdModal: true}))}
              >
                {hold} {intl.formatMessage({id: 'ORDER'})}
              </div>
            )}
            {time && <div className='mt-1 text-gray-600'>{time}</div>}
          </div>
        ))}
      </div>
    </>
  )
}

const JourneyStatusCard: React.FC<Props> = () => {
  const intl = useIntl()
  const {a1Batch, journeyStatus} = useA1BatchesProvider()
  const {currentUser} = useAuth()

  const customDateTime = (date, showTime = false) => {
    if (isEmpty(date)) return {date: null, time: null}

    const dateTime = convertUserTimeZone(date, currentUser, A1_CONSTANTS.DATE_FORMAT)

    const [datePart, timePart, period] = dateTime.split(' ')
    return {
      date: datePart,
      ...(showTime && {time: `${timePart} ${period}`}),
    }
  }

  const flightInFo = useMemo(() => {
    const origin = find(a1Batch?.flights, {type: 'origin'})
    const destination = find(a1Batch?.flights, {type: 'destination'})
    return {origin, destination}
  }, [a1Batch?.flights])

  const dataRowsFirst = [
    {
      label: a1Batch?.readied_at ? intl.formatMessage({id: 'LABEL_CREATED'}) : null,
      value: [customDateTime(a1Batch?.readied_at, true)],
    },
    {
      label:
        a1Batch?.estimated_pickup_at || a1Batch?.actual_pickup_at
          ? intl.formatMessage({id: 'PICK_UP_DATE'})
          : null,
      value: [
        customDateTime(a1Batch?.estimated_pickup_at),
        customDateTime(a1Batch?.actual_pickup_at),
      ],
    },
    {
      label:
        flightInFo?.origin?.actual_departure_at || flightInFo?.origin?.actual_arrival_at
          ? 'ETD/ETA'
          : null,
      value: [
        customDateTime(flightInFo?.origin?.actual_departure_at),
        customDateTime(flightInFo?.origin?.actual_arrival_at),
      ],
    },
    {
      label: a1Batch?.released_at ? intl.formatMessage({id: 'RELEASED'}) : null,
      value: [customDateTime(a1Batch?.released_at)],
    },
    {label: null, value: [{date: null, time: null}]},
    {label: null, value: [{date: null, time: null}]},
    {label: null, value: [{date: null, time: null}]},
  ]

  const dataRowsSecond = [
    {label: null, value: [{date: null, time: null}]},
    {label: null, value: [{date: null, time: null}]},
    {
      label:
        flightInFo?.destination?.actual_departure_at || flightInFo?.destination?.actual_arrival_at
          ? 'ATD/ATA'
          : null,
      value: [
        customDateTime(flightInFo?.destination?.actual_departure_at),
        customDateTime(flightInFo?.destination?.actual_arrival_at),
      ],
    },
    {
      label: a1Batch?.held_orders ? intl.formatMessage({id: 'HOLD'}) : null,
      value: [{date: null, hold: a1Batch?.held_orders}],
    },
    {label: null, value: [{date: null, time: null}]},
    {label: null, value: [{date: null, time: null}]},
    {label: null, value: [{date: null, time: null}]},
  ]

  return (
    <div className='card mt-4 mt-xxl-0 ms-xxl-4 h-100'>
      <div className='card-header border-0 d-flex justify-content-between align-items-center fw-bolder'>
        <span className='fs-3'>{intl.formatMessage({id: 'JOURNEY_STATUS'})}</span>
      </div>
      <div className='card-body d-flex flex-column scroll-x pb-6'>
        <div className='d-flex text-center mb-6'>
          {Object.entries(omit(A1_CONSTANTS.OPTIONS_JOURNEY_STATUS, 'N_A')).map(([key, status]) => (
            <div className='col-7-equal min-w-100px position-relative' key={key}>
              <div className='w-100 border border-2 border-secondary position-absolute top-50 start-50 translate-middle' />
              <KTSVG
                path={`/media/gori/a1/${
                  journeyStatus.statusPrimary === status.value
                    ? 'checkbox_checked'
                    : 'checkbox_unchecked'
                }.svg`}
                style={{color: journeyStatus.statusPrimary === status.value ? status.color : ''}}
                className='svg-icon-1 position-absolute top-50 start-50 translate-middle'
              />
            </div>
          ))}
        </div>
        <div className='d-flex text-center fs-5 fs-xxl-7 fw-bolder mb-4'>
          {Object.entries(omit(A1_CONSTANTS.OPTIONS_JOURNEY_STATUS, 'N_A')).map(([key, status]) => (
            <StatusRow label={intl.formatMessage({id: status.label})} key={key} />
          ))}
        </div>
        <div className='d-flex text-center fs-6 fs-xxl-8 fw-bolder mb-2'>
          {dataRowsFirst.map(({label, value}, idx) => (
            <StatusRow label={label} key={idx} />
          ))}
        </div>
        <div className='d-flex text-center fs-6 fs-xxl-8 fw-bolder mb-4'>
          {dataRowsFirst.map(({value}, idx) => (
            <DataRow value={value} key={idx} />
          ))}
        </div>
        <div className='d-flex text-center fs-6 fs-xxl-8 fw-bolder mb-2'>
          {dataRowsSecond.map(({label}, idx) => (
            <StatusRow label={label} key={idx} />
          ))}
        </div>
        <div className='d-flex text-center fs-6 fs-xxl-8 fw-bolder'>
          {dataRowsSecond.map(({value}, idx) => (
            <DataRow value={value} key={idx} />
          ))}
        </div>
      </div>
    </div>
  )
}

export {JourneyStatusCard}
