import clsx from 'clsx'
import {FormikProps, getIn} from 'formik'
import {cloneDeep, find, isEmpty, isEqual} from 'lodash'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {DEFAULT_COLOR, INVALID_COLOR, VALID_COLOR} from '../../../../../_gori/constants'
import {KTSVG, toAbsoluteUrl} from '../../../../../_gori/helpers'
import {useStatesGlobal} from '../../../../../_gori/helpers/components/StatesGlobalProvider'
import {Button} from '../../../../../_gori/partials/widgets'
import {CreatePackagesModal} from '../../../settings'
import {OPTION_PACKAGE_TYPE} from '../../core/_const'

type Props = {
  data: any
  textLabel?: string
  labelClassName?: any
  required?: boolean
  formik: FormikProps<any>
  name: string
  placeholder?: string
  className?: any
  showAddPackage?: boolean
  disabled?: boolean
}

const PackageType: React.FC<Props> = ({
  data,
  textLabel,
  labelClassName,
  required,
  formik,
  name,
  placeholder = '',
  className,
  showAddPackage = true,
  disabled = false,
}) => {
  const storeRef = useRef<any>(null)
  const intl = useIntl()
  const {getPackages} = useStatesGlobal()
  const [visibleMenu, setVisibleMenu] = useState(false)
  const [visibleSubMenu, setVisibleSubMenu] = useState<string | undefined>(undefined)
  const [labelPackageType, setLabelPackageType] = useState<string | undefined>(placeholder)
  const [showModalNewPackage, setShowModalNewPackage] = useState<boolean>(false)
  const showInputDimension = !!data.find((option) =>
    isEqual(option, OPTION_PACKAGE_TYPE.INPUT_DIMENSION)
  )
  const prevParcelsRef = useRef(null)

  let fieldProps = formik.getFieldProps(name)
  let formikErrors = getIn(formik.errors, name)
  let formikTouched = getIn(formik.touched, name)
  let isInvalid = formikTouched && formikErrors
  let isValid = formikTouched && !formikErrors && fieldProps.value

  const customStyle = {
    borderColor: isValid ? VALID_COLOR : isInvalid ? INVALID_COLOR : DEFAULT_COLOR,
    borderWidth: '1px',
    borderStyle: 'solid',
    width: '100%',
    '&:hover': {
      borderColor: isValid ? VALID_COLOR : isInvalid ? INVALID_COLOR : DEFAULT_COLOR,
    },
    boxShadow: isValid ? VALID_COLOR : isInvalid ? INVALID_COLOR : DEFAULT_COLOR,
    backgroundImage: isValid
      ? `url(${toAbsoluteUrl('/media/gori/valid.svg')})`
      : isInvalid
      ? `url(${toAbsoluteUrl('/media/gori/invalid.svg')})`
      : '',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 3.5rem center',
    backgroundSize: 'calc(0.75em + 0.75rem) calc(0.75em + 0.75rem)',
    backgroundColor: (disabled && '#eff2f5') || '#ffffff ',
    fontSize: '1.1rem',
    fontWeight: '500',
    color: '#5e6278',
    lineHeight: '1.5',
    padding: '3px',
  }

  const handleCreatePackagesSuccess = async (values) => {
    await getPackages()
    await formik.setFieldValue('parcels.0', {
      ...formik.values.parcels[0],
      ...values,
      package_type: '',
    })
  }

  const handleDropdownClick = () => {
    setVisibleMenu(!visibleMenu)
  }

  const openChoose = useCallback(() => {
    let found = false
    let parcelsCurrent = cloneDeep(formik.values?.parcels?.[0])
    data?.forEach((item) => {
      const {label, options} = item
      options &&
        options.forEach((subItem) => {
          if (found) return
          const {
            value: {package_type, package_id},
          } = subItem

          const checkEqual = options.filter((item) => {
            return (
              item.value.length === Number(formik.values.parcels[0].length) &&
              item.value.width === Number(formik.values.parcels[0].width) &&
              item.value.height === Number(formik.values.parcels[0].height) &&
              item.value.dimension_unit === formik.values.parcels[0].dimension_unit
            )
          })
          const itemFound = find(checkEqual, {label: subItem.label})
          if (
            package_type === formik.values.parcels[0]?.package_type &&
            package_id === formik.values.parcels[0]?.package_id &&
            itemFound
          ) {
            found = true
            setVisibleSubMenu(label)
            if (label === OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE.label) {
              parcelsCurrent = {...parcelsCurrent, package_id: package_id}
            } else {
              parcelsCurrent = {...parcelsCurrent, package_type: package_type}
            }
            return setLabelPackageType(itemFound.label)
          } else {
            if (labelPackageType === placeholder) return
            if (labelPackageType !== OPTION_PACKAGE_TYPE.INPUT_DIMENSION.label) {
              parcelsCurrent = {...parcelsCurrent, package_type: '', package_id: null}
              return setLabelPackageType(
                showInputDimension ? OPTION_PACKAGE_TYPE.INPUT_DIMENSION.label : placeholder
              )
            }
          }
        })
      formik.setFieldValue('parcels.0', parcelsCurrent)
    })
  }, [data, formik, labelPackageType, placeholder, showInputDimension])

  useEffect(() => {
    const currentParcels = formik.values?.parcels?.[0]
    const prevParcels = prevParcelsRef.current

    if (!isEqual(prevParcels, currentParcels)) {
      openChoose()
      prevParcelsRef.current = formik.values?.parcels?.[0]
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.parcels?.[0]])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (storeRef.current && !storeRef.current.contains(event.target)) {
        setVisibleMenu(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [openChoose])

  const renderMenuItem = (item, index) => {
    const {label, options, value} = item

    const highLightPackageCarrier = (subItem) => {
      const parcelsCurrent = formik.values?.parcels?.[0]
      if (!parcelsCurrent) return false
      const {
        value: {package_type, package_id},
      } = subItem

      const checkEqual = options.filter((item) => {
        return (
          item.value.length === Number(parcelsCurrent?.length) &&
          item.value.width === Number(parcelsCurrent?.width) &&
          item.value.height === Number(parcelsCurrent?.height) &&
          item.value.dimension_unit === parcelsCurrent?.dimension_unit
        )
      })
      const itemFound = find(checkEqual, {label: subItem.label})
      if (
        package_type === parcelsCurrent?.package_type &&
        package_id === parcelsCurrent?.package_id &&
        itemFound
      ) {
        return true
      } else {
        return false
      }
    }

    const handleClickPackage = () => {
      if (label === OPTION_PACKAGE_TYPE.INPUT_DIMENSION.label) {
        setVisibleMenu(false)
        setVisibleSubMenu(label)
        const initObj = {
          ...formik.values?.parcels?.[0],
          length: '',
          width: '',
          height: '',
          dimension_unit: 'in',
          package_id: null,
          package_type: '',
        }
        formik.setFieldValue('parcels.0', initObj)
      } else {
        setVisibleSubMenu((prev) => (prev === label ? undefined : label))
      }
    }

    const handleClickPackageChild = (subItem) => {
      setVisibleMenu(false)
      formik.setFieldValue('parcels.0', {
        ...formik.values.parcels[0],
        ...subItem.value,
      })
    }

    return (
      <div className={clsx('dropdown-submenu', {show: visibleSubMenu === label})} key={index}>
        <div
          className={clsx('dropdown-submenu__item item-custom', {
            show: visibleSubMenu === label,
          })}
          onClick={handleClickPackage}
        >
          {label === OPTION_PACKAGE_TYPE.INPUT_DIMENSION.label ||
          label === OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE.label
            ? intl.formatMessage({id: label})
            : label}
        </div>
        <div
          className={clsx('dropdown-submenu__inner', {show: visibleSubMenu === label})}
          style={{overflowY: 'auto', maxHeight: '200px'}}
        >
          {!isEmpty(options) &&
            options?.map((subItem, index) => {
              return (
                <div
                  key={index}
                  className={`dropdown-submenu__inner__item item-custom ${
                    highLightPackageCarrier(subItem)
                      ? 'text-white bg-primary'
                      : 'text-hover-primary bg-hover-active-primary'
                  }`}
                  onClick={() => handleClickPackageChild(subItem)}
                >
                  {subItem.label}
                </div>
              )
            })}
        </div>
        {OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE.label === label &&
          visibleSubMenu === label &&
          showAddPackage && (
            <div
              key={index}
              className={`dropdown-submenu__inner__item item-custom`}
              onClick={() => {
                setVisibleMenu(false)
                formik.setFieldValue('parcels.0.package_type', value)
                setShowModalNewPackage(true)
              }}
            >
              <Button
                className='btn btn-link py-0'
                label={`+ ${intl.formatMessage({id: 'ADD_CUSTOM_PACKAGE'})}`}
              />
            </div>
          )}
      </div>
    )
  }

  return (
    <>
      <CreatePackagesModal
        show={showModalNewPackage}
        handleClose={() => {
          formik.setFieldValue('parcels.0.package_type', '')
          setShowModalNewPackage(false)
        }}
        handleCreatePackagesSuccess={handleCreatePackagesSuccess}
      />
      {textLabel && (
        <label className={`form-label ${labelClassName} ${required ? 'required' : ''}`}>
          {textLabel}
        </label>
      )}
      <div className={clsx('dropdown', className, {'cursor-no-drop': disabled})} ref={storeRef}>
        <div
          className={clsx('dropdown__label py-3 px-3', {'pe-none': disabled})}
          onClick={handleDropdownClick}
          style={customStyle}
        >
          <div className='dropdown__label__left fw-bold'>
            <div>
              {labelPackageType === OPTION_PACKAGE_TYPE.INPUT_DIMENSION.label ||
              labelPackageType === OPTION_PACKAGE_TYPE.CUSTOM_PACKAGE.label
                ? intl.formatMessage({id: labelPackageType})
                : labelPackageType}
            </div>
          </div>
          <div
            style={{
              borderLeft: '1px solid hsl(0, 0%, 80%)',
              paddingLeft: '6px',
              boxSizing: 'border-box',
              transition: 'color 150ms',
              fill: 'hsl(0, 0%, 80%)',
            }}
            className='dropdown__label__arrow'
          >
            <KTSVG path='/media/gori/select_arrow.svg' svgClassName={''} small={false} />
          </div>
        </div>
        <div
          className={clsx('dropdown-menu dropdown__menu  mw-100', {show: visibleMenu})}
          style={{width: '100%'}}
        >
          {data.map((item, index) => renderMenuItem(item, index))}
        </div>
        {formikTouched && formikErrors && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block text-danger'>
              <span role='alert'>{formikErrors}</span>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export {PackageType}
