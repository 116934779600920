import {isEmpty, startCase} from 'lodash'
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import HttpStatusCode from '../../../../app/api/HttpStatusCode'

type ErrorField = {
  input?: string
  id: string
  max?: string
  noIntl?: string
}

type Props = {
  handleClose?: () => void
  response?: {
    data?: {
      error?: {
        message?: string
        fields?: Record<string, any>
        log_id?: string
      }
    }
    status?: number
  }
}

const ValidationErrorModal: React.FC<Props> = ({handleClose, response}) => {
  const intl = useIntl()
  const [title, setTitle] = useState<string>()
  const [arrErrorsModal, setArrErrorsModal] = useState<ErrorField[]>([])

  const parseErrors = (errors: Record<string, any>): ErrorField[] => {
    const parsedErrors: ErrorField[] = []

    Object.entries(errors).forEach(([name, value]) => {
      value.forEach((err) => {
        const [field, id, max] = err.split('|')
        if (!name.includes('.')) {
          parsedErrors.push({
            input: field ? startCase(field) : undefined,
            id,
            max,
          })
        } else {
          parsedErrors.push({noIntl: err, id: 'noIntl'})
        }
      })
    })

    return parsedErrors.filter((item) => !isEmpty(item))
  }

  useEffect(() => {
    const errorData = response?.data?.error
    if (!errorData) return

    setTitle(errorData.message)

    if (response?.status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
      const parsedErrors = parseErrors(errorData.fields || {})
      setArrErrorsModal(parsedErrors)
    }

    return () => setArrErrorsModal([])
  }, [response])

  const renderError = (error: ErrorField, index: number) => {
    const {input, id, max, noIntl} = error

    return (
      <li key={index} className='fw-bolder fs-3 text-danger'>
        {noIntl ? noIntl : intl.formatMessage({id}, {input, max})}
      </li>
    )
  }

  return (
    <Modal
      id='gori_modal_error'
      tabIndex={-1}
      aria-hidden='true'
      centered
      show={!isEmpty(response)}
      onHide={handleClose}
      dialogClassName='h-auto mw-650px'
    >
      <div className='modal-content'>
        <Modal.Header closeButton>
          <Modal.Title bsPrefix='fw-bolder fs-1' />
        </Modal.Header>
        <Modal.Body className='vh-75 scroll-y bg-light-danger'>
          {title && (
            <div className='d-flex justify-content-center fw-bolder fs-3 text-danger mb-5'>
              {intl.formatMessage({id: title})}
              {!!response?.data?.error?.log_id &&
                intl.formatMessage({id: 'LOG_ID'}, {log_id: response.data.error.log_id})}
            </div>
          )}
          {arrErrorsModal.length > 0 && <ul>{arrErrorsModal.map(renderError)}</ul>}
        </Modal.Body>
      </div>
    </Modal>
  )
}

export {ValidationErrorModal}
