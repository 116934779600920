import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

type Props = {
  event?: any
  id?: string
  className?: string
  classNameDiv?: string
  label?: any
  loading?: boolean
  loadingText?: any
  disabled?: boolean
  children?: any
  submit?: boolean
  hover?: string
  icon?: any
}

const Button: FC<Props> = ({
  event,
  id,
  className,
  classNameDiv,
  label,
  loading = false,
  loadingText,
  disabled = false,
  children,
  submit = false,
  hover,
  icon,
}) => {
  const intl = useIntl()

  return (
    <div className={clsx(classNameDiv, {'cursor-no-drop': disabled || loading})}>
      <button
        type={submit ? 'submit' : 'button'}
        id={id}
        className={clsx(className ?? 'btn btn-success', {
          'pe-none': loading,
        })}
        disabled={loading || disabled}
        onClick={event}
        title={hover}
      >
        {children}
        {!loading && (
          <>
            {icon}
            <span className='indicator-label'>{label}</span>
          </>
        )}
        {loading && (
          <span className='indicator-progress' style={{display: 'block'}}>
            {loadingText ?? intl.formatMessage({id: 'PLEASE_WAIT'})}
            <span className='spinner-border spinner-border-sm align-middle ms-2' />
          </span>
        )}
      </button>
    </div>
  )
}

export {Button}
