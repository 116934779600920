import {FC, useCallback, useEffect, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {useSearchParams} from 'react-router-dom'
import {DEFAULT_PAGE} from '../../../constants'
import {isEmpty} from 'lodash'
import {Button} from '../form/Button'

type Props = {
  placeholder?: string
}

const InputSearch: FC<Props> = ({placeholder = ''}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchText, setSearchText] = useState(searchParams.get('search_text') || '')

  const handleSearch = useCallback(
    (e) => {
      if (isEmpty(searchText)) {
        searchParams.delete('search_text')
      } else {
        searchParams.set('search_text', searchText)
      }

      if (e.key === 'Enter' || isEmpty(searchText)) {
        searchParams.set('page', DEFAULT_PAGE)
        setSearchParams(searchParams)
      }
    },
    [searchParams, searchText, setSearchParams]
  )

  useEffect(() => {
    setSearchText(searchParams.get('search_text') || '')
  }, [searchParams])

  return (
    <>
      <KTSVG path='/media/gori/magnify.svg' className='svg-icon-1 position-absolute ms-4' />
      <input
        type='text'
        autoComplete='off'
        className='form-control form-control-solid mw-250px px-14'
        placeholder={placeholder}
        onChange={(e) => setSearchText(e.target.value)}
        onKeyUp={(e) => handleSearch(e)}
        value={searchText}
      />
      {searchText && (
        <Button
          event={() => {
            setSearchText('')
            searchParams.delete('search_text')
            setSearchParams(searchParams)
          }}
          classNameDiv='d-flex justify-content-center align-items-center'
          className='text-hover-dark border-0 bg-transparent rounded-circle py-1 position-absolute end-0 me-4'
        >
          <KTSVG
            path='/media/gori/orders/delete.svg'
            className='opacity-50 m-0'
            svgClassName='mh-10px'
          />
        </Button>
      )}
    </>
  )
}

export {InputSearch}
