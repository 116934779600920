import React, {useContext, useState} from 'react'
import {
  A1BatchesProviderModel,
  JourneyInfoModel,
  JourneyStatusModel,
} from '../../app/modules/a1/core/_models'

const initA1BatchContextPropsState = {
  a1Batch: null,
  setA1Batch: () => {},
  journeyInfo: {
    from: null,
    to: null,
    estimated_pickup_at: null,
    services: null,
    pallets: null,
    parcels: null,
    weight: null,
    mawb: null,
  },
  setJourneyInfo: () => {},
  journeyStatus: {
    statusPrimary: null,
  },
  setJourneyStatus: () => {},
}

const A1BatchContext = React.createContext<A1BatchesProviderModel>(initA1BatchContextPropsState)

const A1BatchesProvider: React.FC = ({children}) => {
  const [a1Batch, setA1Batch] = useState<any>(initA1BatchContextPropsState.a1Batch)
  const [journeyInfo, setJourneyInfo] = useState<JourneyInfoModel>(
    initA1BatchContextPropsState.journeyInfo
  )
  const [journeyStatus, setJourneyStatus] = useState<JourneyStatusModel>(
    initA1BatchContextPropsState.journeyStatus
  )

  return (
    <A1BatchContext.Provider
      value={{a1Batch, setA1Batch, journeyInfo, setJourneyInfo, journeyStatus, setJourneyStatus}}
    >
      {children}
    </A1BatchContext.Provider>
  )
}

function useA1BatchesProvider() {
  return useContext(A1BatchContext)
}

export {A1BatchesProvider, useA1BatchesProvider}
